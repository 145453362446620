import { createSlice } from "@reduxjs/toolkit";
import { arrToObjMap, getArrOrder } from "../../../shared/constants";

const initialState = {
  citiesMap: {},
  list: [],
  loading: false,
  error: null,
};

const handleLoading = (state) => {
  state.error = null;
  state.loading = true;
};

const handleSuccess = (state, action) => {
  const { payload } = action;
  let list = [...state.list, ...getArrOrder(payload?.data)];
  state.list = [...new Set(list)];
  state.citiesMap = Object.assign(
    {},
    state.citiesMap,
    arrToObjMap(payload?.data)
  );
  state.loading = false;
};

const onCreateSuccess = (state, action) => {
  const { payload } = action;
  const data = payload?.data;
  let list = [...state.list, data?._id];

  state.list = [...new Set(list)];
  state.citiesMap = Object.assign({}, state.citiesMap, { [data?._id]: data });
  state.loading = false;
};
const onUpdateSuccess = (state, action) => {
  const { payload } = action;
  const data = payload?.data;
  let list = [...state.list, data?._id];

  state.list = [...new Set(list)];
  state.citiesMap = Object.assign({}, state.citiesMap, { [data?._id]: data });
  state.loading = false;
};
const onDeleteSuccess = (state, action) => {
  const { payload } = action;
  const data = payload?.data;

  state.list = state.list?.filter((id) => id !== data?._id);
  state.citiesMap = Object.assign({}, state.citiesMap, { [data?._id]: data });
  state.loading = false;
};

const handleError = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    onGetCities: handleLoading,
    onUpdateCity: handleLoading,
    onCreateCity: handleLoading,
    onDeleteCity: handleLoading,
    onHandleSuccess: handleSuccess,
    onCreateOneSuccess: onCreateSuccess,
    onUpdateOneSuccess: onUpdateSuccess,
    onDeleteOneSuccess: onDeleteSuccess,
    onHandleError: handleError,
  },
});

export const cityActions = citySlice.actions;

export default citySlice.reducer;
