import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-awesome-modal";
import { useNavigate,useSearchParams } from "react-router-dom";
import { getPress_detail } from "../auth/service";

const Linkdetail = () => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams()
  const[data,setData]= useState({})
  const [state, updatestate] = useState(false);
  const openModal = (e) => {
    updatestate(true);
  };
  const closeModal = () => {
    updatestate(false);
    navigate(-1);
  };
  const getdetail = async (param) => {
    const res = await getPress_detail({press_id:param});
    if (res?.data) {
      setData(res?.data);
    }
  };
  useMemo(() => {
    const id = searchparams.get("id");
    getdetail(id);
    openModal();
  }, []);
  return (
    <>
      <Modal
        visible={state}
        width="400"
        height="max-content"
        effect="fadeInUp"
        onClickAway={closeModal}
      >
        <div className="details_main">
          <img
            src={data?.src}
            height="200px"
            width="100%"
          ></img>
          <p className="blogcard_title" style={{width:"50%", fontSize:"10px"}}>{data?.title}</p>
          {/* <p className="blogcard_subtitle" id="">http://google.com/</p> */}
        </div>
      </Modal>
    </>
  );
};

export default Linkdetail;
