import React, { useEffect, useState } from "react";
import { get_Jobs } from "../../features/auth/service";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Link, TextField } from "@mui/material";
import "../ContactUs/contactPage.css";
import { BASE_URL } from "../../shared/constants";

const GetJobs = () => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("obenev-token");

  const getdetails = async () => {
    // const res = await get_Jobs();
    // setData(res?.data?.data);
    // const res = await axios.get("https://api.obenelectric.com/get-jobs", {
    const res = await axios.get(`${BASE_URL}/get-jobs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(res.data.data);
  };

  const handleClick = () => {
    console.log(data);
  };

  // const handelFilter = (e) => {
  //   // setData(e.target.value)
  //   data.filter(e.target.value),data.firstName
  //   console.log(e.target.value)
  // }

  useEffect(() => {
    getdetails();
  }, []);
  return (
    <div className="contact-container">
      <div
        style={{
          fontSize: "2rem",
          fontWeight: 600,
          fontFamily: "Poppins",
          cursor: "pointer",
          // display: "flex",
        }}
      >
        Jobs
        {/* <TextField id="outlined-basic" label="Search" variant="outlined" /> */}
      </div>

      <TableContainer component={Paper} sx={{ my: 2 }}>
        <Table sx={{ minWidth: 650, px: 2 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" className="tablecell" sx={{ pl: 5 }}>
                First Name
              </TableCell>
              <TableCell align="left" className="tablecell">
                Last Name
              </TableCell>
              <TableCell align="left" className="tablecell">
                Email
              </TableCell>
              <TableCell align="left" className="tablecell">
                Phone
              </TableCell>
              <TableCell align="left" className="tablecell">
                Document
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log(data.reverse())}
            {data.map((row) => {
              return (
                <TableRow
                  key={row?._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left" component="th" scope="row">
                    <Box
                      className="origin_des"
                      sx={{ width: "50", justifyContent: "start" }}
                    >
                      <Box sx={{ ml: 3 }}>
                        <Typography className="font600 fs_14">
                          {row?.firstName}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="left">{row?.lastName}</TableCell>
                  <TableCell align="left">{row?.email}</TableCell>
                  <TableCell align="left">{row?.phone}</TableCell>
                  <Link href={row.src} underline="hover">
                    View Resume
                  </Link>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GetJobs;
