import React, { useCallback, useState } from "react";
import "../category.css";
import {
  Accordion,
  AccordionDetails,
  Button,
  CircularProgress,
} from "@mui/material";
import { Add, ArrowBack, CloseOutlined } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { create_Faq_Category, uploadImage } from "../../auth/service";
import axios from "axios";
import CategoryListItems from "../common/CategoryListItems";
import EditCategory from "../common/EditCategoryItem";
import EditCategoryItem from "../common/EditCategoryItem";
import DeleteIcon from "@mui/icons-material/Delete";

const AddCategory = () => {
  const [category, setCategory] = useState("");
  const [slug, setSlug] = useState("");
  const [qna, setQna] = useState([]);
  const [question, setQuestion] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [answer, setAnswer] = useState("");
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [altTag, setAltTag] = useState("");
  const [errors, setErros] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditid] = useState(null);

  const navigate = useNavigate();
  const handleNavigate = async () => {
    navigate("/dashboard/faq/category-list");
  };

  const handleModal = (value) => {
    setIsModal(value);
  };

  const error = {};
  const validateFields = () => {
    if (!category) {
      error.category = "Please enter your category name";
    }
    return error;
  };

  const categoryValidation = () => {
    if (!question.trim()) {
      error.questiion = "Please enter your question";
    }
    if (!answer.trim()) {
      error.answer = "Please enter your answer";
    }

    return error;
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);

    if (category !== "") {
      setErros("");
    }
  };

  const addQna = () => {
    const validation = categoryValidation();
    setErros(validation);
    if (question !== "" && answer !== "" && validation) {
      let addQuestion = question;
      const newQna = {
        id: qna.length + 1, // Assuming each item has a unique id
        meta_title: metaTitle,
        meta_description: metaDescription,
        slug: slug,
        questiion: addQuestion,
        answer: answer,
      };

      setQna([...qna, newQna]);
      setIsModal(false);
      setQuestion("");
      setAnswer("");
    }
  };

  const createCategory = async () => {
    let categoryValidation = "category";
    const validation = validateFields(categoryValidation);
    setErros(validation);

    if (category !== "") {
      try {
        const resp = await create_Faq_Category({
          name: category,
          active: true,
          faqs: qna,
          meta: {
            image_src: imageUrl,
            image_alt_tag: altTag,
          },
        });

        // Check if response indicates success
        if (!resp?.error) {
          toast.success(resp?.message);
          setTimeout(() => {
            navigate("/dashboard/faq/category-list");
          }, 1500);
        } else {
          // If response indicates an error, show the error message
          toast.success(resp.message || "An error occurred", "error");
        }
      } catch (error) {
        // Handle error from API call
        toast.success("Failed to create category", "error");
      }
    }
  };

  const handleDeleteItem = (index) => {
    setQna(qna.filter((qna) => qna?.id !== index));
    toast.success("Deleted successfully");
  };

  const handleChildIdChange = (id) => {
    setEditid(id);
  };

  const hadnleEditIsModal = (boolean) => {
    setEditModal(boolean);
  };

  const UpdateQna = (updateValue) => {
    const newQna = {
      id: updateValue?.id, // Assuming each item has a unique
      meta_title: updateValue?.meta_title,
      meta_description: updateValue?.meta_description,
      slug: updateValue?.slug,
      questiion: updateValue?.questiion,
      answer: updateValue?.answer,
    };
    const updatedData = qna.filter((item) => item?.id !== editId);

    setQna(
      [...updatedData, newQna].sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    );
  };

  const handleImageAltTag = (e) => {
    setAltTag(e.target.value);
  };
  let formData = new FormData();
  const getSelectedFile = useCallback(
    async (e) => {
      try {
        setIsUploadingImg(true);
        const file = e.target.files[0];
        formData.append("image", file);

        const res = await uploadImage(formData);
        if (res.status === "success") {
          const image = res?.data?.Location;
          setImageUrl(image);
        } else {
        }
      } catch (error) {
        // setError("Something went wrong");
      } finally {
        setIsUploadingImg(false);
      }
    },
    [formData]
  );

  return (
    <div className="category-add">
      <div className="category-header-container">
        <span className="blog-header">Add Category</span>

        <div className="btn-flex">
          <div className="btn-container ">
            <Button
              variant="contained"
              className="bordered-button"
              size="medium"
              onClick={createCategory}
            >
              SAVE CATEGORY
            </Button>
          </div>
          <div className="btn-container ">
            <Button
              variant="outlined"
              className="bordered-button"
              startIcon={<ArrowBack />}
              size="medium"
              onClick={handleNavigate}
            >
              BACK
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <label htmlFor="inputField" className="label">
            Category Name
          </label>

          <br />
          <div>
            <input
              type="text"
              id="inputField"
              className="input"
              name="category"
              value={category}
              onChange={handleCategory}
            />
            {errors?.category && (
              <p className="faqs-error" style={{ display: "block" }}>
                {errors.category}
              </p>
            )}
          </div>
        </div>

        <div className="container">
          <label htmlFor="inputField" className="label">
            Upload Image
          </label>
          {imageUrl == null ? (
            <>
              {isUploadingImg ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  className="bordered-button"
                  style={{ width: 200, fontSize: 14, color: "#fff" }}
                  component="label"
                >
                  Upload Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={getSelectedFile}
                    hidden
                  />
                </Button>
              )}
            </>
          ) : (
            <>
              <div style={{ maxWidth: 400 }} className="mUpload-item">
                <img src={imageUrl} width="80px" alt="img" />
                <input
                  className="fw"
                  type="text"
                  placeholder="Enter alt tag"
                  value={altTag}
                  onChange={handleImageAltTag}
                  name="alt_tag"
                />
                <DeleteIcon onClick={() => setImageUrl(null)} />
              </div>
            </>
          )}
        </div>
        <div className="container">
          <label htmlFor="inputField" className="label">
            Faqs
          </label>
          <Button
            onClick={() => handleModal(true)}
            variant="outlined"
            size="small"
            startIcon={<Add />}
          >
            ADD QNA
          </Button>
        </div>

        {qna?.length > 0
          ? qna?.map((qnalist) => {
              return (
                <React.Fragment key={qnalist?.id}>
                  <CategoryListItems
                    qnalist={qnalist}
                    onDelete={handleDeleteItem}
                    onIdChange={handleChildIdChange}
                    editModal={hadnleEditIsModal}
                  />
                </React.Fragment>
              );
            })
          : ""}
      </div>
      <ToastContainer />
      {isModal ? (
        <div>
          <Accordion>
            <AccordionDetails>
              <div className="Quil-maodal-header">
                <h3>Add Faq</h3>
                <CloseOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => handleModal(false)}
                />
              </div>
              <div className="Quil-one">
                <div className="form_comp">
                  <label className="title" htmlFor="title">
                    Meta Title
                  </label>
                  <input
                    className="textField"
                    type="text"
                    id="title"
                    placeholder="Enter Title"
                    name="metaTitle"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                    bounds={`[data-text-editor="name"]`}
                  />
                </div>
                <div className="form_comp">
                  <label className="title" htmlFor="title">
                    Meta Description
                  </label>
                  <input
                    className="textField"
                    type="text"
                    id="title"
                    placeholder="Enter Title"
                    name="metaDescription"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                    bounds={`[data-text-editor="name"]`}
                  />
                </div>
                <div className="form_comp">
                  <label className="title" htmlFor="title">
                    Slug
                  </label>
                  <input
                    className="textField"
                    type="text"
                    id="title"
                    placeholder="Enter Title"
                    name="slug"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    bounds={`[data-text-editor="name"]`}
                  />
                </div>
                <div className="form_comp">
                  <label className="title" htmlFor="title">
                    Question
                  </label>
                  <ReactQuill
                    className="textField"
                    type="text"
                    id="title"
                    placeholder="Enter Title"
                    name="question"
                    value={question}
                    onChange={(value) => setQuestion(value)}
                    bounds={`[data-text-editor="name"]`}
                  />
                </div>
                {errors?.questiion && (
                  <p className="faqs-error" style={{ display: "block" }}>
                    {errors.questiion}
                  </p>
                )}
              </div>

              <div className="Quil-two">
                <label className="title" htmlFor="title">
                  Answer
                </label>

                <div className="form_comp">
                  <ReactQuill
                    className="textField"
                    type="text"
                    id="title"
                    placeholder="Enter Title"
                    name="answer"
                    value={answer}
                    onChange={(value) => setAnswer(value)}
                    bounds={`[data-text-editor="name"]`}
                  />
                </div>
                {errors?.answer && (
                  <p className="faqs-error" style={{ display: "block" }}>
                    {errors.answer}
                  </p>
                )}
              </div>
              <div className="btn-group">
                <Button
                  className="quil-close-btn"
                  size="medium"
                  variant="outlined"
                  onClick={() => handleModal(false)}
                >
                  Close
                </Button>
                <Button size="medium" variant="contained" onClick={addQna}>
                  Add
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        ""
      )}

      {editModal ? (
        <EditCategoryItem
          id={editId}
          qna={qna}
          pullUpdateQna={UpdateQna}
          editModal={hadnleEditIsModal}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default AddCategory;
