import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback } from "react";
import ReactQuill from "react-quill";

const BasicAccordian = (props) => {
  const { title, data, setData } = props;

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;

      setData((preState) => ({ ...preState, [name]: value }));
    },
    [setData]
  );

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography fontWeight={"600"}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="form_comp">
            <label htmlFor="title">Title</label>
            <ReactQuill
              placeholder="Enter title"
              value={data?.title}
              onChange={handleChange("title")}
              theme="snow"
              className="edit_container"
              bounds={`[data-text-editor="name"]`}
            />
          </div>
          <div className="form_comp">
            <label htmlFor="title">Description</label>
            <ReactQuill
              placeholder="Enter description"
              value={data?.description}
              onChange={handleChange("description")}
              theme="snow"
              className="edit_container"
              bounds={`[data-text-editor="name"]`}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BasicAccordian;
