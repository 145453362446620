import React, { useCallback, useEffect, useState } from "react";
import "../Testimonial.css";
import TestimonialCard from "./TestimonialCard";
import { Button, CircularProgress } from "@mui/material";
import AddTestimonmonialModal from "./AddTestimonmonialModal";
import { getAll_Testimonial } from "../../auth/service";

const TestimonailList = () => {
  const [showModal, setShowModal] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [testimonialId, setTestimonialId] = useState("");

  const handleModal = useCallback((show = false, id) => {
    show = typeof show === "boolean" && show;
    setShowModal(show);
    setTestimonialId(id);
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAll_Testimonial();

      setReviewList(response?.data);
    } catch (error) {
      // setError(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!showModal) {
      fetchData();
    }
  }, [showModal]);
  return (
    <div className="testimonial-list">
      <div className="testimonial-header-container">
        <span className="testimonial-header">Campaign Review</span>

        <div className="btn-container">
          <Button
            variant="contained"
            className="bordered-button"
            onClick={() => handleModal(true)}
          >
            ADD CAMPAIGN REVIEW
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
        }}
      >
        {isLoading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              right: "25%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        {reviewList?.length > 0
          ? reviewList?.map((reviewList) => {
              return (
                <div
                  className="testimonial-card-container"
                  key={reviewList?._id}
                >
                  <TestimonialCard
                    pullHandleData={handleModal}
                    reviewList={reviewList}
                    active={reviewList?.active}
                  />
                </div>
              );
            })
          : ""}
      </div>

      <AddTestimonmonialModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        testimonialId={testimonialId}
      />
    </div>
  );
};

export default TestimonailList;
