import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const handleLoading = (state) => {
  state.error = null;
  state.loading = true;
};

const handleSuccess = (state, action) => {
  state.data = action.payload ?? null;
  state.loading = false;
};

const handleError = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    onGetStore: handleLoading,
    onUpdateStore: handleLoading,
    onCreateStore: handleLoading,
    onHandleSuccess: handleSuccess,
    onHandleError: handleError,
  },
});

export const storeActions = storeSlice.actions;

export default storeSlice.reducer;
