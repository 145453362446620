import React from "react";

import { Navigate } from "react-router-dom";
import {
  adminInfoGetterService,
  adminInfoRemovalService,
} from "../../shared/services";

const PrivateRoute = ({ children }) => {
  const token = adminInfoGetterService();
  return token ? children : <Navigate to="/" />;
};

export default PrivateRoute;
