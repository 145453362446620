import React from "react";

import { CircularProgress, Container } from "@mui/material";

import "./style.css";

const Loader = () => {
  return (
    <Container className="loading-container">
      <CircularProgress size={80} className="loading-spinner" />
    </Container>
  );
};

export default Loader;
