import React, { useEffect } from "react";
import "./performance-report.css";
import { Button, InputLabel, FormControl, TextField } from "@mui/material";
import axios from "axios";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { BASE_URL } from "../../shared/constants";

const PerformanceReport = () => {
  let [data, setData] = React.useState([]);
  let [searchData, setSearchData] = React.useState([]);
  let [text, setText] = React.useState("");

  useEffect(() => {
    getdetails();
  }, []);
  const getdetails = async () => {
    const res = await axios.get(`${BASE_URL}/user/interim-storage`, {});
    setData(res?.data?.data || []);
    setSearchData(res?.data?.data || []);
  };

  const exportData = async () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(
      blob,
      "Interim Storage " + moment.utc().format("DD-MMM-YYYY HH:MM") + ".xlsx"
    );
  };

  const filterData = async () => {
    const res = await axios.get(
      `${BASE_URL}/user/interim-storage?text=${text}`,
      {}
    );
    setSearchData(res?.data?.data || []);
  };

  const handleFilter = (e) => {
    setText(e.target.value);
    if (e.target.value === "") {
      setSearchData(data);
    }
  };


  return (
    <div className="pr-m-wrapper">
      <div className="pr-header-container">
        <span className="pr-header">Interim Storage</span>
        <div className="btn-container">
          <Button
            onClick={exportData}
            variant="contained"
            className="bordered-button"
          >
            Export Data
          </Button>
        </div>
      </div>

      <div className="filter-cb">
        <FormControl
          sx={{
            minWidth: 200,
          }}
          size="small"
        >
          <TextField
            id="outlined-basic"
            label="Filter Data"
            variant="outlined"
            className="input-set"
            onChange={handleFilter}
          />
        </FormControl>

        <Button
          onClick={filterData}
          variant="contained"
          className="bordered-button"
        >
          Filter
        </Button>
      </div>

      <div className="pr-outer-table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">City</th>
              <th scope="col">Bike Model</th>
              <th scope="col">Full Name</th>
              <th scope="col">Mobile Number</th>
              <th scope="col">Email ID</th>
              <th scope="col">Showroom</th>
              <th scope="col">Whatsapp Number</th>
              <th scope="col">OTP Verified</th>
            </tr>
          </thead>
          <tbody>
            {searchData?.map((o, i) => (
              <tr key={i}>
                <td>
                  {o?.SlotTime}
                </td>
                <td>{o.City}</td>
                <td>{o?.BikeName}</td>
                <td>{o?.FirstName + " " + o?.LastName}</td>
                <td>{o?.Mobile}</td>
                <td>{o?.Email}</td>
                <td>{o?.ShowroomName}</td>
                <td>{o?.WhatsAppNumber}</td>
                <td>{o?.Is_Mobile_Verified_c ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PerformanceReport;
