import { Box, Button, Modal, Rating } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { uploadImage } from "../../auth/service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const AddTestimonialModal = (props) => {
  const { open, handleClose, selectedTestimonial, handleTestimonial } = props;

  const prevProp = useRef();
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [state, setState] = useState({
    name: "",
    description: "",
    location: "",
    imgAlt: "",
    src: "",
    rating: 0,
  });
  const [error, setError] = useState({});

  useEffect(() => {
    if (!prevProp?.current?.open && open) {
      setState({
        name: selectedTestimonial?.name ?? "",
        description: selectedTestimonial?.description ?? "",
        location: selectedTestimonial?.location ?? "",
        imgAlt: selectedTestimonial?.imgAlt ?? "",
        src: selectedTestimonial?.src ?? "",
        rating: selectedTestimonial?.rating ?? 0,
      });
      setError({});
    }

    return () => {
      prevProp.current = {
        open,
      };
    };
  }, [open, selectedTestimonial]);

  const getSelectedFile = useCallback(async (e) => {
    let formData = new FormData();
    try {
      setIsUploadingImg(true);
      const file = e.target.files[0];
      formData.append("image", file);

      const res = await uploadImage(formData);
      if (res.status === "success") {
        const image = res?.data?.Location;
        setState((prevData) => ({ ...prevData, src: image }));
      }
      setError({});
    } catch (error) {
      setError("Something went wrong");
    } finally {
      setIsUploadingImg(false);
    }
  }, []);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      if (name === "rating") {
        value = Number(value);
      }
      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const modalTitle = useMemo(
    () => (selectedTestimonial ? `Edit Testimonial` : `Add Testimonial`),
    [selectedTestimonial]
  );

  const hasError = useCallback(() => {
    let { name, description, location, rating, src } = state;
    name = name?.trim?.();
    description = description?.trim?.();
    location = location?.trim?.();

    const error = {};

    if (!name) {
      error.name = "Name cannot be blank";
    }

    if (!description) {
      error.description = "Description cannot be blank";
    }
    if (!location) {
      error.location = "Location cannot be blank";
    }

    if (!rating) {
      error.rating = "Rating is required";
    }

    if (!src) {
      error.fileList = "Upload image";
    }

    setError(error);
    return Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const payload = {
        ...state,
      };

      if (selectedTestimonial && selectedTestimonial.index !== undefined)
        payload.index = selectedTestimonial.index;
      handleTestimonial(payload);
      handleClose(false);
    }
  }, [hasError, state, selectedTestimonial, handleTestimonial, handleClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>{modalTitle}</h2>
        <div className="form_comp">
          <label htmlFor="title">
            Name
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <input
            type="text"
            placeholder="Enter Name"
            value={state.name}
            onChange={handleChange("name")}
            autoComplete="off"
          />

          <span className="error mt5">{error.name}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Description
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <textarea
            rows="2"
            placeholder="Enter description"
            value={state.description}
            onChange={handleChange("description")}
          />
          <span className="error mt5">{error.description}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Location
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <textarea
            rows="2"
            placeholder="Enter location"
            value={state.location}
            onChange={handleChange("location")}
          />

          <span className="error mt5">{error.location}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Image <sup style={{ color: "red" }}>*</sup>
          </label>
          {!state?.src ? (
            <>
              {isUploadingImg ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  className="bordered-button"
                  style={{ width: 200, fontSize: 14, color: "#fff" }}
                  component="label"
                >
                  Upload Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={getSelectedFile}
                    hidden
                  />
                </Button>
              )}
            </>
          ) : (
            <>
              <div style={{ maxWidth: 400 }} className="mUpload-item">
                <img src={state?.src} width="80px" alt="img" />
                <input
                  className="fw"
                  type="text"
                  placeholder="Enter alt tag"
                  value={state?.imgAlt}
                  onChange={handleChange("imgAlt")}
                />
                <DeleteIcon
                  onClick={() =>
                    setState((preState) => ({ ...preState, src: "" }))
                  }
                />
              </div>
            </>
          )}
          <span className="error mt5">{error.fileList}</span>
        </div>

        <div className="form_comp">
          <label htmlFor="title">
            Rating
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <Rating
            style={{ width: "max-content" }}
            name="simple-controlled"
            value={state.rating}
            onChange={handleChange("rating")}
          />
          <span className="error mt5">{error.rating}</span>
        </div>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            gap: 15,
          }}
        >
          <Button variant="contained" onClick={onSubmit}>
            {selectedTestimonial ? "Update" : "Add"}
          </Button>
          <Button
            variant="text"
            className="bordered-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddTestimonialModal;
