import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCityModal from "../components/AddCityModal";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { cityActions } from "../duck/CityReducer";
import CircularProgress from "@mui/material/CircularProgress";

const AddCityAccordian = (props) => {
  const { title, expanded } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { list, citiesMap, loading } = useSelector(({ city }) => city);

  useEffect(() => {
    dispatch(cityActions.onGetCities());
  }, [dispatch]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCityId, selectCity] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleModal = useCallback((show = false, id) => {
    show = typeof show === "boolean" && show;
    setShowModal(show);
    selectCity(id);
  }, []);

  const handleRemove = useCallback(
    (_id) => {
      dispatch(cityActions.onDeleteCity({ _id }));
    },
    [dispatch]
  );

  const handlePopover = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <Typography fontWeight={"600"}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="btn-container mt15">
            <Button
              variant="contained"
              className="bordered-button float-right"
              onClick={() => handleModal(true)}
            >
              + Add City
            </Button>
          </div>

          {loading ? (
            <CircularProgress style={{ marginLeft: "auto" }} />
          ) : (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table sx={{ minWidth: 650, px: 2 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ fontWeight: 700 }}
                      align="left"
                      className="tablecell"
                    >
                      State
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: 700 }}
                      align="left"
                      className="tablecell"
                    >
                      City
                    </TableCell>

                    <TableCell
                      style={{ fontWeight: 700 }}
                      align="left"
                      className="tablecell"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {list?.map((cityid, index) => {
                    const mCity = citiesMap?.[cityid];
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" component="th" scope="row">
                          <Box className="origin_des">
                            <Box>
                              <Typography
                                style={{
                                  maxWidth: 200,
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                                className="font600 fs_14"
                              >
                                {mCity?.state}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                          <Box className="origin_des">
                            <Box
                              sx={{
                                ml: 3,
                              }}
                            >
                              <Typography
                                style={{
                                  maxWidth: 400,
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                                className="font600 fs_14"
                              >
                                {mCity?.city}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell align="left">
                          <MoreVertIcon id={cityid} onClick={handlePopover} />
                          <Popover
                            id="simple-popover"
                            open={anchorEl?.id === cityid}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography
                              style={{
                                padding: 5,
                                alignItems: "center",
                                textAlign: "center",
                              }}
                              className="pointer"
                              onClick={() =>
                                navigate(`/dashboard/editStore/${mCity?._id}`)
                              }
                            >
                              <RemoveRedEyeIcon
                                style={{ fontSize: 16 }}
                                className="mr5"
                              />
                              Details
                            </Typography>
                            <Typography
                              className="pointer"
                              onClick={() => handleModal(true, mCity?._id)}
                            >
                              <EditIcon
                                style={{ fontSize: 16 }}
                                className="mr10"
                              />
                              Edit
                            </Typography>
                            <Typography
                              className="pointer"
                              onClick={() => handleRemove(mCity?._id)}
                            >
                              <DeleteIcon
                                style={{ fontSize: 16 }}
                                className="mr5"
                              />
                              Delete
                            </Typography>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>
      <AddCityModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        selectedCityId={selectedCityId}
      />
    </>
  );
};

export default AddCityAccordian;
