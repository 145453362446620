import { Box, Button, Modal, Rating } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isValidMobileNumber } from "../../../shared/utils";
import ReactQuill from "react-quill";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const AddShowRoomModal = (props) => {
  const { open, handleClose, selectedStore, handleStore, storeType } = props;

  const prevProp = useRef();

  const [state, setState] = useState({
    name: "",
    address: "",
    map: "",
    direction: "",
    contactNumber: "",
    rating: 0,
  });
  const [error, setError] = useState({});

  useEffect(() => {
    if (!prevProp?.current?.open && open) {
      setState({
        name: selectedStore?.name ?? "",
        address: selectedStore?.address ?? "",
        map: selectedStore?.map ?? "",
        direction: selectedStore?.direction ?? "",
        contactNumber: selectedStore?.contactNumber ?? "",
        rating: selectedStore?.rating ?? 0,
      });
      setError({});
    }

    return () => {
      prevProp.current = {
        open,
      };
    };
  }, [open, selectedStore]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      if (name === "contactNumber") {
        value = value.replace(/[^0-9]/g, "");
      }
      if (name === "rating") {
        value = Number(value);
      }
      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const modalTitle = useMemo(
    () => (selectedStore ? `Edit ${storeType}` : `Add ${storeType}`),
    [selectedStore, storeType]
  );

  const hasError = useCallback(() => {
    let { name, address, map, contactNumber, rating, direction } = state;
    name = name?.trim?.();
    address = address?.trim?.();

    const error = {};

    if (!name) {
      error.name = "Name cannot be blank";
    }

    if (!address) {
      error.address = "Address cannot be blank";
    }
    if (!direction) {
      error.direction = "Map's direction cannot be blank";
    }
    if (!map) {
      error.map = "Embed link required";
    }
    if (!contactNumber) {
      error.contactNumber = "Contact number is required";
    }
    if (!rating) {
      error.rating = "Rating is required";
    }
    if (contactNumber && !isValidMobileNumber(contactNumber)) {
      error.contactNumber = "Invalid number";
    }

    setError(error);
    return Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const payload = {
        ...state,
      };

      if (selectedStore && selectedStore.index !== undefined)
        payload.index = selectedStore.index;
      handleStore(payload);
      handleClose(false);
    }
  }, [hasError, state, selectedStore, handleStore, handleClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>{modalTitle}</h2>
        <div className="form_comp">
          <label htmlFor="title">
            Name
            <sup style={{ color: "red" }}>*</sup>
          </label>

          <input
            type="text"
            placeholder="Enter Name"
            value={state.name}
            onChange={handleChange("name")}
            autoComplete="off"
          />
          <span className="error mt5">{error.name}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Address
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <ReactQuill
            placeholder="Enter address"
            value={state.address}
            onChange={handleChange("address")}
            theme="snow"
            className="edit_container"
            bounds={`[data-text-editor="name"]`}
          />

          <span className="error mt5">{error.address}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Google Map's embed link
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <textarea
            rows="2"
            placeholder="Enter iframe"
            value={state.map}
            onChange={handleChange("map")}
          />
          <span className="error mt5">{error.map}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Direction
            <sup style={{ color: "red" }}>*</sup>
          </label>

          <input
            type="text"
            placeholder="Eg. goo.gl/maps/YFAvikeRhVRSYGbh7"
            value={state.direction}
            onChange={handleChange("direction")}
            autoComplete="off"
          />
          <span className="error mt5">{error.direction}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Contact Number
            <sup style={{ color: "red" }}>*</sup>
          </label>

          <input
            type="text"
            placeholder="Enter contact number"
            value={state.contactNumber}
            onChange={handleChange("contactNumber")}
            autoComplete="off"
          />
          <span className="error mt5">{error.contactNumber}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Rating
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <Rating
            style={{ width: "max-content" }}
            name="simple-controlled"
            value={state.rating}
            onChange={handleChange("rating")}
          />
          <span className="error mt5">{error.rating}</span>
        </div>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            gap: 15,
          }}
        >
          <Button variant="contained" onClick={onSubmit}>
            {selectedStore ? "Update" : "Add"}
          </Button>
          <Button
            variant="text"
            className="bordered-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddShowRoomModal;
