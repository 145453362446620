import React from "react";

import { Route, Routes } from "react-router-dom";

import Login from "./features/auth";
import SidebarLayout from "./features/side-bar/SidebarLayout";
import PrivateRoute from "./features/routes/PrivateRoute";
import PublicRoute from "./features/routes/PublicRoute";
import Blog from "./features/blog/Blog";
import NewsLink from "./features/newsLink/NewsLink";
import Blogdetail from "./features/blog/Blogdetail";
import CreateForm from "./features/blog/CreateForm";
// import DeleteModal from "./features/Common/DeleteModal";
import Linkdetail from "./features/newsLink/Linkdetail";
import Createlink from "./features/newsLink/Createlink";
import "./App.css";
import Deletelink from "./features/newsLink/Deletelink";
import Contactpage from "./features/ContactUs/Contactpage";
import GetJobs from "./features/Jobs/GetJobs";
import StoreLocattion from "./features/store-locations/StoreLocattion";
import EditStorePage from "./features/store-locations/components/EditStorePage";
import BannerBlogAccordion from "./features/blog/accordians/BannerListing";
import CreateBanner from "./features/blog/accordians/CreateBanner";
import BannerListing from "./features/blog/accordians/BannerListing";
import BannerDetail from "./features/blog/accordians/BannerDetail";
import CategoryList from "./features/FaqCategory/components/CategoryList";
import AddCategory from "./features/FaqCategory/components/AddCategory";
import EditCategory from "./features/FaqCategory/components/EditCategory";
import TestimonailList from "./features/Testimonial/components/TestimonailList";
import PerformanceReport from "./features/performance-report/PerformanceReport";

function App() {
  return (
    <Routes>
      <Route element={<SidebarLayout />}>
        <Route
          path="/dashboard/blog"
          element={
            <PrivateRoute>
              <Blog />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/banner"
          element={
            <PrivateRoute>
              <BannerListing />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard/newslink"
          element={
            <PrivateRoute>
              <NewsLink />
            </PrivateRoute>
          }
        />
        <Route
          bannerdetail
          path="/dashboard/blogdetail"
          element={
            <PrivateRoute>
              <Blogdetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/bannerdetail"
          element={
            <PrivateRoute>
              <BannerDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/linkdetail"
          element={
            <PrivateRoute>
              <Linkdetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/create/blog"
          element={
            <PrivateRoute>
              <CreateForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/create/banner"
          element={
            <PrivateRoute>
              <CreateBanner />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/create/link"
          element={
            <PrivateRoute>
              <Createlink />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/contact-us"
          element={
            <PrivateRoute>
              <Contactpage />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/jobs"
          element={
            <PrivateRoute>
              <GetJobs />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/store-location"
          element={
            <PrivateRoute>
              <StoreLocattion />
            </PrivateRoute>
          }
        />
        {["/dashboard/editStore", "/dashboard/editStore/:id"].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <PrivateRoute>
                <EditStorePage />
              </PrivateRoute>
            }
          />
        ))}
        <Route
          path="/dashboard/delete/link"
          element={
            <PrivateRoute>
              <Deletelink />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/faq/category-list"
          element={
            <PrivateRoute>
              <CategoryList />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard/faq/category-add"
          element={
            <PrivateRoute>
              <AddCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/faq/category-edit/:id"
          element={
            <PrivateRoute>
              <EditCategory />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard/testimonial-list"
          element={
            <PrivateRoute>
              <TestimonailList />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/interim-storage"
          element={
            <PrivateRoute>
              <PerformanceReport />
            </PrivateRoute>
          }
        />
      </Route>

      <Route
        exact
        path="/"
        element={
          // <PublicRoute>
          <Login />
          // </PublicRoute>
        }
      />
    </Routes>
  );
}

export default App;
