import React, { useState } from "react";
import { Box, Typography, Modal, Link } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "./DeleteModal";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate, createSearchParams } from "react-router-dom";

const UserTable = ({ data, type, setEdit, setAction, setCreateManager }) => {
  console.log("type", type);
  const navigate = useNavigate();
  const handleEdit = (details) => {
    setEdit(details);
    setAction("edit");
    setCreateManager(true);
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    // window.location.reload();
  };
  const [del, setDel] = useState("");

  const handleOpen = (id) => {
    setOpen(true);
    setDel(id);
  };
  const getDetails = (detail) => {
    if (type == "Blog") {
      navigate({
        pathname: "/dashboard/blogdetail",
        search: createSearchParams({
          id: detail._id,
        }).toString(),
      });
    } else if (type == "Banner") {
      navigate({
        pathname: "/dashboard/bannerdetail",
        search: createSearchParams({
          id: detail._id,
        }).toString(),
      });
    } else {
      navigate({
        pathname: "/dashboard/linkdetail",
        search: createSearchParams({
          id: detail._id,
        }).toString(),
      });
    }
  };
  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 650, px: 2 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" className="tablecell" sx={{ pl: 5 }}>
                Title
              </TableCell>
              {type === "Link" ? (
                <>
                  {/* <TableCell align="left" className="tablecell">
                    Subtitle
                  </TableCell> */}
                  <TableCell align="left" className="tablecell">
                    Category
                  </TableCell>
                </>
              ) : type === "Banner" ? (
                <>
                  <TableCell align="left" className="tablecell">
                    Description
                  </TableCell>
                  <TableCell align="left" className="tablecell">
                    Category
                  </TableCell>
                </>
              ) : (
                // For other cases when type is neither "Link" nor "Banner"
                <TableCell align="left" className="tablecell">
                  SubTitle
                </TableCell>
              )}

              {/*  */}
              {type === "Blog" ? (
                <TableCell align="left" className="tablecell"></TableCell>
              ) : type === "Banner" ? (
                <TableCell align="left" className="tablecell"></TableCell>
              ) : (
                <TableCell align="left" className="tablecell">
                  View
                </TableCell>
              )}

              <TableCell align="left" className="tablecell">
                Edit
              </TableCell>
              <TableCell align="left" className="tablecell">
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log(data.reverse())}
            {/* {data.reverse()} */}
            {data.map((row) => {
              return (
                <TableRow
                  key={row?._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left" component="th" scope="row">
                    <Box
                      className="origin_des"
                      sx={{ width: "60%", justifyContent: "start" }}
                    >
                      <Box sx={{ ml: 3 }}>
                        {type === "Blog" ? (
                          <Typography className="font600 fs_14">
                            {row?.title}
                          </Typography>
                        ) : type === "Banner" ? (
                          <>
                            <Typography className="font600 fs_14">
                              <div
                                dangerouslySetInnerHTML={{ __html: row?.title }}
                              />
                            </Typography>
                          </>
                        ) : (
                          <Typography className="font600 fs_14">
                            {row?.content}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </TableCell>

                  {type === "Blog" ? (
                    <>
                      {/* <TableCell align="left">
                        Subtitle content
                      </TableCell> */}
                      {/* <TableCell align="left">
                        {row?.content?.slice(0, 300)}
                      </TableCell> */}
                      <TableCell align="left">{row?.subTitle}</TableCell>
                    </>
                  ) : type === "Banner" ? (
                    <>
                      <TableCell align="left">
                        <div
                          dangerouslySetInnerHTML={{ __html: row?.description }}
                        />
                      </TableCell>
                      <TableCell align="left" onClick={() => getDetails(row)}>
                        {row.category}
                      </TableCell>
                    </>
                  ) : (
                    <TableCell align="left" onClick={() => getDetails(row)}>
                      {row.category}
                    </TableCell>
                  )}

                  {/* <TableCell align="left">link</TableCell> */}
                  {type === "Blog" ? (
                    <TableCell align="left" onClick={() => getDetails(row)}>
                      {/* You can place your content or icon here */}
                    </TableCell>
                  ) : type === "Banner" ? (
                    <TableCell align="left"></TableCell>
                  ) : (
                    <TableCell align="left">
                      {/* <RemoveRedEyeIcon /> */}
                      <Link href={row.title} underline="hover">
                        Go to press
                      </Link>
                    </TableCell>
                  )}

                  <TableCell align="left" onClick={() => handleEdit(row)}>
                    <EditIcon />
                  </TableCell>
                  <TableCell align="left" onClick={() => handleOpen(row._id)}>
                    <DeleteIcon />
                  </TableCell>
                  {/*  <TableCell align="left">
                    {type === "Banner" && <div>active</div>}
                  </TableCell>*/}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <DeleteModal handleClose={handleClose} type={type} id={del} />
        </Box>
      </Modal>
    </>
  );
};

export default UserTable;
