import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useCallback, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactQuill from "react-quill";
import { uploadImage } from "../../auth/service";
import CircularProgress from "@mui/material/CircularProgress";

const BannerAccordion = (props) => {
  // console.log("props", props);
  const { title, data, setData } = props;

  const [error, setError] = useState("");
  const [isUploadingImg, setIsUploadingImg] = useState(false);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;

      setData((preState) => ({ ...preState, [name]: value }));
    },
    [setData]
  );
  const getSelectedFile = useCallback(
    async (e) => {
      let formData = new FormData();
      try {
        setIsUploadingImg(true);
        const file = e.target.files[0];
        formData.append("image", file);

        const res = await uploadImage(formData);
        if (res.status === "success") {
          const image = res?.data?.Location;
          setData((prevData) => ({ ...prevData, src: image }));
        }
      } catch (error) {
        setError("Something went wrong");
      } finally {
        setIsUploadingImg(false);
      }
    },
    [setData]
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography fontWeight={"600"}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="form_comp">
          <label htmlFor="title">Image Title</label>
          <ReactQuill
            value={data?.imgTitle}
            onChange={handleChange("imgTitle")}
            placeholder="Enter image title"
            theme="snow"
            className="edit_container"
            bounds={`[data-text-editor="name"]`}
          />
        </div>
        <div className="form_comp">
          <label htmlFor="title">Image Description</label>
          <ReactQuill
            placeholder="Enter image description"
            value={data?.imgDescription}
            onChange={handleChange("imgDescription")}
            theme="snow"
            className="edit_container"
            bounds={`[data-text-editor="name"]`}
          />
        </div>
        <div className="form_comp">
          <label htmlFor="title">Image</label>
          {!data?.src ? (
            <>
              {isUploadingImg ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  className="bordered-button"
                  style={{ width: 200, fontSize: 14, color: "#fff" }}
                  component="label"
                >
                  Upload Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={getSelectedFile}
                    hidden
                  />
                </Button>
              )}
            </>
          ) : (
            <>
              <div style={{ maxWidth: 400 }} className="mUpload-item">
                <img src={data?.src} width="80px" alt="img" />
                <input
                  className="fw"
                  type="text"
                  placeholder="Enter alt tag"
                  value={data?.imgAlt}
                  onChange={handleChange("imgAlt")}
                />
                <DeleteIcon
                  onClick={() =>
                    setData((preState) => ({ ...preState, src: "" }))
                  }
                />
              </div>
            </>
          )}
          {error && <span className="error mt5">{error}</span>}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default BannerAccordion;
