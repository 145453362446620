const constants = Object.freeze({
  BLOG_CATEGORIES: [
    "Trending",
    "Performance",
    "Charging",
    "Battery",
    "Design",
    "Tech",
    "News",
  ],
});

export default constants;
