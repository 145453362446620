import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Opacity } from "@mui/icons-material";

const CardCategory = ({ categorylist, onIdChange, active }) => {
  const navigate = useNavigate();
  const handleSendEditId = (id) => {
    onIdChange(id);
    navigate(`/dashboard/faq/category-edit/${id}`);
  };

  return (
    <Card style={{ height: "100%", opacity: `${!active ? "0.5" : ""}` }}>
      <div className="box-wrapper">
        <CardHeader
          style={{ textTransform: "capitalize" }}
          title={categorylist?.name}
          //   subheader="Select a sport from the menu"
          action={
            <>
              {/* <EditIcon /> */}
              <Button onClick={() => handleSendEditId(categorylist?._id)}>
                <EditIcon />
              </Button>
            </>
          }
        />
        <hr />
        <CardContent>
          <Typography className="card-que-body">
            {categorylist?.faqs[0]?.questiion?.replace(/(<([^>]+)>)/gi, "")}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
};

export default CardCategory;
