import { axiosInstance } from "./axios";

export const adminLogoutService = async () => {
  try {
    const { data } = await axiosInstance.post(`/admin/logout`);
    return { isSuccess: data.response.message === "successfully logged out." };
  } catch (e) {
    return {
      error: e.response.data,
    };
  }
};

export const adminInfoGetterService = () => {
  return localStorage.getItem("obenev-token");
};

export const adminInfoStorageService = (admin) => {
  // console.log(admin,"admin")
  localStorage.setItem("obenev-token", admin.access_token);
};

export const adminInfoRemovalService = () => {
  localStorage.removeItem("obenev-token");
};

// export const fetchCountryCodes = async () => {
//   try {
//     const { data } = await axiosInstance.get(`/country/get`);
//     return data.response;
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };
