import React from "react";
import JoditEditor from "jodit-react";

function JoditEditior({ content, setContent }) {
  return (
    <div style={{ width: "100%" }}>
      <div className="text-editor">
        <JoditEditor
          value={content}
          tabIndex={1}
          onBlur={(newContent) => {
            if (newContent !== content) {
              setContent(newContent);
            }
          }}
          config={{
            minHeight: 300,
          }}
        />
      </div>
    </div>
  );
}

export default JoditEditior;
