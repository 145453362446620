import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-awesome-modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getblog_Detail } from "../auth/service";
import ReactHtmlParser from "react-html-parser";

const Blogdetail = () => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const [data, setData] = useState({});
  const [state, updatestate] = useState(false);
  // const [gend, setGend] = useState()
  const openModal = (e) => {
    updatestate(true);
  };
  const closeModal = () => {
    updatestate(false);
    navigate(-1);
  };
  const getdetail = async (param) => {
    console.log("Hello");
    const res = await getblog_Detail({ blog_id: param });
    if (res?.data) {
      setData(res?.data);
    }
  };
  useMemo(() => {
    const id = searchparams.get("id");
    getdetail(id);
    openModal();
  }, []);

  console.log(data)
  // setGend(data?.content)

  const content = data.content
  // const [gend, setGend] = useState(parse(data?.content?.slice(0,300)))
  return (
    <>
      <Modal
        visible={state}
        width="700"
        height="400"
        // height="auto"
        effect="fadeInUp"
        onClickAway={closeModal}
        >
        <div className="details_main">
          <img src={data?.src} height="200px" width="100%"></img>
          <p className="blogcard_title">{data?.title}</p>
          {/* <p className="blogcard_subtitle">subtitle</p> */}
          <div>{ReactHtmlParser(content)}</div>
        </div>
      </Modal>
    </>
  );
};

export default Blogdetail;
