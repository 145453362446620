import React, { useEffect, useState } from "react";
import "../category.css";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CardCategory from "./CardCategory";
import { getAll_Faq_Category } from "../../auth/service";

const CategoryList = () => {
  const [categorylist, setCategorylist] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editId, setEditid] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = async () => {
    navigate("/dashboard/faq/category-add");
  };

  const fetchData = async () => {
    try {
      const response = await getAll_Faq_Category();
      setCategorylist(response?.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleChildIdChange = (pullId) => {
    setEditid(pullId);
  };
  // console.log(categorylist, editId);
  return (
    <div className="category-list">
      <div className="category-header-container">
        <span className="blog-header">FAQs Category</span>

        <div className="btn-container">
          <Button
            variant="contained"
            className="bordered-button"
            onClick={handleNavigate}
          >
            ADD FAQ CATEGORY
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          //   justifyContent: "space-around",
        }}
      >
        {isLoading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              right: "25%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        {categorylist?.length > 0
          ? categorylist?.map((categorylist) => {
              return (
                <div
                  className="category-card-container"
                  key={categorylist?._id}
                >
                  <CardCategory
                    categorylist={categorylist}
                    onIdChange={handleChildIdChange}
                    active={categorylist?.active}
                  />
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default CategoryList;
