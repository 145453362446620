import React, { useEffect, useState } from "react";
import UserTable from "../Common/UserTable";
import { useNavigate } from "react-router-dom";
import { getBlogs } from "../auth/service";
import CreateForm from "./CreateForm";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import "./blog.css";
import constants from "../../Utils/Constants";

const { BLOG_CATEGORIES } = constants;

const Blog = () => {
  const [createManager, setCreateManager] = useState(false);
  const [Edit_detail, setEdit] = useState({});
  const [action, setAction] = useState("");
  const createRole = () => {
    setCreateManager(true);
  };
  const [data, setData] = useState([]);
  const getAllBlogs = async (category = "") => {
    const res = await getBlogs({ category });

    if (Array.isArray(res?.data)) {
      console.log(res?.data);
      const blogs = res.data.map((blog) => {
        blog._id = blog.id;
        return blog;
      });
      console.log("blogs", blogs);
      setData(blogs);
    } else {
      setData([]);
    }
  };
  // const getAllBlogsBlog = async () => {
  //   const res = await getBlogs({ category: "Blog" });
  //   if (res?.data) {
  //     console.log(res?.data);
  //     setData(res?.data);
  //   }
  // };
  // const getAllBlogsTrending = async () => {
  //   const res = await getBlogs({ category: "Trending" });
  //   if (res?.data) {
  //     console.log(res?.data);
  //     setData(res?.data);
  //   }
  // };
  // const getAllBlogsPerformance = async () => {
  //   const res = await getBlogs({ category: "Performance" });
  //   if (res?.data) {
  //     console.log(res?.data);
  //     setData(res?.data);
  //   }
  // };
  // const getAllBlogsCharging = async () => {
  //   const res = await getBlogs({ category: "Charging" });
  //   if (res?.data) {
  //     console.log(res?.data);
  //     setData(res?.data);
  //   }
  // };
  // const getAllBlogsBattery = async () => {
  //   const res = await getBlogs({ category: "Battery" });
  //   if (res?.data) {
  //     console.log(res?.data);
  //     setData(res?.data);
  //   }
  // };
  // const getAllBlogsDesign = async () => {
  //   const res = await getBlogs({ category: "Design" });
  //   if (res?.data) {
  //     console.log(res?.data);
  //     setData(res?.data);
  //   }
  // };
  // const getAllBlogsTech = async () => {
  //   const res = await getBlogs({ category: "Tech" });
  //   if (res?.data) {
  //     console.log(res?.data);
  //     setData(res?.data);
  //   }
  // };
  // const getAllBlogsNews = async () => {
  //   const res = await getBlogs({ category: "News" });
  //   if (res?.data) {
  //     console.log(res?.data);
  //     setData(res?.data);
  //   }
  // };
  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <div className="blogs-container">
      {createManager ? (
        <CreateForm action={action} blogdetails={Edit_detail} />
      ) : (
        <>
          <div className="blog-container">
            <div className="blog-header-container">
              <span className="blog-header">Blog</span>

              <div className="btn-container">
                <Button
                  variant="contained"
                  className="bordered-button"
                  onClick={createRole}
                >
                  Create a Blog
                </Button>
              </div>
            </div>
          </div>
          {/*} <div
          style={{
            margin: "2rem ",
            float: "right",
            fontFamily: "Poppins",
            cursor: "pointer",
          }}
          onClick={createRole}
        >
          Create a Blog
        </div>*/}

          <FormControl
            sx={{
              m: 1,
              marginLeft: "-0.2rem",
              minWidth: 200,
            }}
            size="small"
          >
            <InputLabel id="demo-select-small">Filter on categories</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              // value={age}
              label="Filter on categories"
              // onChange={handleChange}
            >
              {/* <MenuItem value="">
                <em>All</em>
              </MenuItem> */}
              <MenuItem value={10} onClick={() => getAllBlogs()}>
                All
              </MenuItem>

              {BLOG_CATEGORIES.map((categorie, i) => (
                <MenuItem
                  value={`${categorie}-${i}`}
                  onClick={() => getAllBlogs(categorie)}
                >
                  {categorie}
                </MenuItem>
              ))}
              {/* <MenuItem value={30} onClick={getAllBlogsTrending}>
                Trending
              </MenuItem>
              <MenuItem value={40} onClick={getAllBlogsPerformance}>
                Performance
              </MenuItem>
              <MenuItem value={50} onClick={getAllBlogsCharging}>
                Charging
              </MenuItem>
              <MenuItem value={60} onClick={getAllBlogsBattery}>
                Battery
              </MenuItem>
              <MenuItem value={70} onClick={getAllBlogsDesign}>
                Design
              </MenuItem>
              <MenuItem value={80} onClick={getAllBlogsTech}>
                Tech
              </MenuItem>
              <MenuItem value={90} onClick={getAllBlogsNews}>
                News
              </MenuItem> */}
            </Select>
          </FormControl>

          <UserTable
            data={data}
            type="Blog"
            setEdit={setEdit}
            setAction={setAction}
            setCreateManager={setCreateManager}
          />
        </>
      )}
    </div>
  );
};

export default Blog;
