import axios from "axios";

export const getFormattedDate = (date) => {
  return new Date(date).toDateString();
};
export const getFormattedTime = (date) => {
  return new Date(date).toLocaleTimeString();
};

export const getCommonColDef = () => {
  return {
    width: 200,
    align: "center",
    headerAlign: "center",
  };
};

export const getDurationInDays = ({ startDate, endDate }) => {
  const durationInMS =
    new Date(endDate).getTime() - new Date(startDate).getTime();
  const durationInDays = durationInMS / 1000 / 60 / 60 / 24;
  return durationInDays;
};

export const cancellableRequest = (callback) => {
  let cancelToken;
  return (payload) => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return callback(payload, cancelToken);
  };
};

export const isValidMobileNumber = (mobile = "") => {
  let format = /^[6789]\d{9}$/;

  return format.test(mobile);
};
