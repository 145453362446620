import React, { useState, useEffect } from "react";
import Add from "../blog/assets/Add.svg";
import { create_Link, edit_link, uploadImage } from "../auth/service";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import Loader from "../../shared/Loader";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import ".././blog/createForm.css";
const Createlink = ({ action, linkdetails }) => {
  // const [getImage, setImage] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    category: "Blog",
    title: "",
    content: "",
    src: "",
  });
  let formData = new FormData();
  const getSelectedFile = async (e) => {
    setLoading(true);
    var file = e.target.files[0];
    formData.append("image", file);
    const res = await uploadImage(formData);
    if (res.status == "success") {
      const image = res?.data?.Location;
      setData({ ...data, src: image });
      setLoading(false);
    }
  };
  const getformData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  const addTitle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, content: value });
  };
  const addSubTitle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, subTitle: value });
  };
  useEffect(() => {
    if (action == "edit") {
      setData({
        ...data,
        title: linkdetails.title,
        category: linkdetails.category,
        src: linkdetails.src,
        content: linkdetails.content,
        subTitle: linkdetails.subTitle,
      });
    }
  }, []);

  const CreateNewLink = async () => {
    let bugs = false;
    try {
      for (let key in data) {
        if (!data[key]) {
          alert("please fill all fields");
          bugs = true;
          break;
        }
      }
      if (!bugs) {
        let resp;
        if (action == "edit") {
          const res = await edit_link({ ...data, pressId: linkdetails?._id });
          resp = res;
        } else {
          const res = await create_Link(data);
          resp = res;
        }
        if (!resp.error) {
          alert(resp.message);
          window.location.reload();
        }
      }
    } catch (error) {
      alert("something is wrong");
    }
  };
  const category = ["Corporate", "Milestone", "Product Review"];

  if (loading !== false) {
    return <Loader />;
  }

  return (
    <>
      <div className="create-form--container">
        <div className="form-header-container">
          <span className="form-header">
            <ArrowBackIosIcon
              className="back_button"
              onClick={() => navigate(0)}
            />
            {action == "edit" ? "Edit Link" : "Create a new Link"}
          </span>

          <div className="btn-container">
            <Button
              className="bordered-button"
              type="button"
              variant="contained"
              color="primary"
              onClick={CreateNewLink}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          padding: "0px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "60%", lg: "60%" },
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
          }}
        >
          <div className="form_comp">
            <label for="link">Title</label>
            <input
              type="text"
              id="link"
              placeholder="Add Content"
              name="link"
              value={data.content}
              onChange={addTitle}
            ></input>
          </div>
          <div>
            {/* <div className="form_comp">
            <label for="title">Title</label>
            <input
              type="text"
              id="title"
              placeholder="Enter Title"
              name="title"
              value={data.title}
              onChange={getformData}
            ></input>
            </div> */}
            <Grid container spacing={2} order={{ xs: 2, md: 1 }}>
              <Grid item xs={12} md={6}>
                <div className="form_comp form_comp_subtitle">
                  <label for="link">Sub Title</label>
                  <input
                    type="text"
                    id="link"
                    placeholder="Add Content"
                    name="link"
                    value={data.subTitle}
                    onChange={addSubTitle}
                  ></input>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="form_comp form_comp_subtitle">
                  <label for="title">Blog link</label>
                  <input
                    type="text"
                    id="title"
                    placeholder="Enter title"
                    name="title"
                    value={data.title}
                    onChange={getformData}
                  ></input>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form_comp">
                  <label for="category">Choose a Category</label>
                  <select
                    name="category"
                    id="category"
                    onChange={getformData}
                    value={data?.category}
                  >
                    {category.map((el) => {
                      return <option value={el}>{el}</option>;
                    })}
                  </select>
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "30%" },
            height: "300px",
          }}
        >
          {data?.src ? (
            <>
              <div
                style={{
                  width: "100%",
                  justifyContent: "right",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{ width: "max-content" }}
                  onClick={() => setData({ ...data, src: "" })}
                >
                  <CancelIcon />
                </div>
              </div>
              <img src={data?.src} height="200px" width="100%" alt="img" />
            </>
          ) : (
            <div sx={{ textAlign: "center" }}>
              <label htmlFor="kyc_doc">
                <div
                  style={{
                    height: "220px",
                    display: "grid",
                    placeItems: "center",

                    marginTop: "20px",
                  }}
                >
                  <img src={Add} alt="upload img" />
                </div>
              </label>
              <input
                type="file"
                id="kyc_doc"
                style={{ display: "none" }}
                accept="image/*"
                onChange={getSelectedFile}
              ></input>
            </div>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Createlink;
