import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-awesome-modal";
import { useNavigate } from "react-router-dom";

const Deletelink = () => {
  const navigate = useNavigate();
  const [state, updatestate] = useState(false);
  const openModal = (e) => {
    updatestate(true);
  };
  const closeModal = () => {
    updatestate(false);
    navigate(-1);
  };
  useMemo(() => {
    openModal();
  }, []);
  return (
    <>
      <Modal
        visible={state}
        width="700"
        height="200"
        effect="fadeInUp"
        // onClickAway={closeModal}
      >
        <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
          <div className="blog_heading" style={{ paddingLeft: 0 }}>
            Are you sure you want to delete this?
          </div>
          <div>
            <button
              type="button"
              className="modal_btn"
              style={{ background: "#312968" }}
              onClick={closeModal}
            >
              cancel
            </button>
            <button
              type="button"
              className="modal_btn"
              style={{ background: "red" }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Deletelink;
