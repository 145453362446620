import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-awesome-modal";
import { delete_blog, delete_link, delete_banner } from "../auth/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const DeleteModal = ({ type, id }) => {
  console.log("Bann", type, id);
  const [state, updatestate] = useState(false);
  const openModal = (e) => {
    updatestate(true);
  };
  const closeModal = () => {
    updatestate(false);
    window.location.reload();
  };
  const deleteItem = async () => {
    try {
      let resp;
      if (type == "Blog") {
        const res = await delete_blog({ blogId: id });
        resp = res;
      } else if (type == "Banner") {
        const res = await delete_banner({ bannerId: id });
        resp = res;
      } else if (type == "Link") {
        const res = await delete_link({ pressId: id });
        resp = res;
      }
      if (!resp.error) {
        toast.success(resp.message);
        closeModal();
      } else {
        toast.error(resp.message);
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };
  useMemo(() => {
    openModal();
  }, []);
  return (
    <>
      <Modal
        visible={state}
        width="700"
        height="200"
        effect="fadeInUp"
        // onClickAway={closeModal}
      >
        <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
          <div className="blog_heading" style={{ paddingLeft: 0 }}>
            Are you sure you want to delete this?
          </div>
          <div>
            <button
              type="button"
              className="modal_btn"
              style={{ background: "#312968" }}
              onClick={closeModal}
            >
              cancel
            </button>
            <button
              type="button"
              className="modal_btn"
              style={{ background: "red" }}
              onClick={deleteItem}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default DeleteModal;
