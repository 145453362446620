import React, { useEffect, useState } from "react";
import UserTable from "../../Common/UserTable";
import { useNavigate } from "react-router-dom";
import { getBanners } from "../../auth/service";
import CreateBanner from "./CreateBanner";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import "../blog.css";
import constants from "../../../Utils/Constants";

const { BLOG_CATEGORIES } = constants;

const BannerListing = () => {
  const [createManager, setCreateManager] = useState(false);
  const [Edit_detail, setEdit] = useState({});
  const [action, setAction] = useState("");
  const createRole = () => {
    setCreateManager(true);
  };
  const [data, setData] = useState([]);
  const getAllBanners = async (category = "") => {
    const res = await getBanners({ category });

    if (Array.isArray(res?.data)) {
      const banners = res.data.map((banner) => {
        return { ...banner, key: banner._id };
      });
      console.log("banners", banners);
      setData(banners);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    getAllBanners();
  }, []);
  return (
    <div className="blogs-container">
      {createManager ? (
        <CreateBanner action={action} bannerdetails={Edit_detail} />
      ) : (
        <>
          <div className="blog-container">
            <div className="blog-header-container">
              <span className="blog-header">Banner</span>

              <div className="btn-container">
                <Button
                  variant="contained"
                  className="bordered-button"
                  onClick={createRole}
                >
                  Create a Banner
                </Button>
              </div>
            </div>
          </div>
          {/*} <div
          style={{
            margin: "2rem ",
            float: "right",
            fontFamily: "Poppins",
            cursor: "pointer",
          }}
          onClick={createRole}
        >
          Create a Blog
        </div>*/}

          <UserTable
            data={data}
            type="Banner"
            setEdit={setEdit}
            setAction={setAction}
            setCreateManager={setCreateManager}
          />
        </>
      )}
    </div>
  );
};

export default BannerListing;
